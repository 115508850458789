// DefaultComponent.js
import React from 'react';
import TagsComponent from './TagsComponent';
import { toggleTagActive } from '../../slices/tagsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/store';

interface Tag {
    tagId: string;
    name: string;
    color?: string;
    active?: boolean;
    variant?: string;
}

interface ReadTagsComponentProps {
    // Add any props if needed
}

const ReadTagsComponent: React.FC<ReadTagsComponentProps> = () => {
    const dispatch = useDispatch();
    const tags = useSelector((state: RootState) => state.tags.items);

    const handleButtonToggle = (tagId: string) => {
        dispatch(toggleTagActive(tagId));
    };

    // Since ReadTagsComponent is read-only, we don't need handleEditableButtonUpdate
    const handleEditableButtonUpdate = () => {
        // This will never be called since includeEditButton is false
    };

    return (
        <div>
            <TagsComponent 
                tags={tags} 
                handleButtonToggle={handleButtonToggle}
                handleEditableButtonUpdate={handleEditableButtonUpdate}
                includeEditButton={false} 
            />
        </div>
    );
}

export default ReadTagsComponent;