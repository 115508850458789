import moment from 'moment';

export const config = {
    apiUrl: process.env.REACT_APP_API_URL || '',
    taskQueryKey: ['tasks', 'personal'],
    habitsQueryKey: ['habits', 'personal'],
    tagQueryKey: ['tags', 'personal'],
    notesQueryKey: ['notes', 'personal']
};

const getCurrentDateExtended = () => {
    const currentDate = new Date();
  
    // Format dateInteger (YYYYMMDDHHMMSS)
    const dateInteger = parseInt(currentDate.toISOString().replace(/[-:T.]/g, '').slice(0, 14));
  
    // Format formattedDate (Month Day, Year)
    const formattedDate = currentDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  
    // Get dateTimeValue (ISO format)
    const dateTimeValue = currentDate.toISOString().slice(0, 19);
  
    return {
      dateInteger,
      formattedDate,
      dateTimeValue
    };
  };

const toggleTag = (setStateFunc, tag) => {
    let newTags;
    setStateFunc((prevState) => {
        // Compute the new value of counter based on the previous state
        if (prevState.includes(tag)) {
            newTags = prevState.filter(item => item !== tag);
        } else {
            newTags = [...prevState];
            newTags.push(tag);
        }
        return newTags;
    });
}

const generateTaskId = (task) => {
    return task.replace(/\s+/g, "").toLowerCase();
}

const getCurrentDate = () => {
    var date = moment();
    return date.format('MMMM D, YYYY');
}

const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
]

const getDayOfWeek = () => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const currentDayName = daysOfWeek[dayOfWeek];
    return currentDayName;
}

var exports = {
    toggleTag,
    generateTaskId,
    getDayOfWeek,
    getCurrentDate,
    getCurrentDateExtended,
    config
};

export default exports;