import React from 'react';
import './elements.css';
import Entry from './Entry';

const EntryList = (props) => {
    const { entryList } = props;

    return (
        <div>
            {entryList && entryList.map((entry) => (
                <div>
                    <Entry entry={entry} />
                </div>
            ))
            }
        </div>
    );
}

export default EntryList;