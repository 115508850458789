import React, { useState, useEffect } from 'react';
import Util from '../Util';
import NotesComponent from '../elements/NotesComponent/NotesComponent';
import TagsComponent from '../elements/TagsComponent';
import { useSelector, useDispatch } from 'react-redux';
import { addTag } from '../../slices/tagsSlice';
import { useQueryClient } from '@tanstack/react-query';
import { updateNoteDraft, createTasks, createNote, createTag } from '../api';
import { useApiClient } from '../../service/useApiClient';

const WritePage = () => {
  const [content, setContent] = useState('');
  const [localTags, setLocalTags] = useState([]);
  const reduxTags = useSelector((state) => state.tags.items);
  const dispatch = useDispatch();
  const [lastSavedDraft, setLastSavedDraft] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const queryClient = useQueryClient();
  const api = useApiClient();

  useEffect(() => {
    debugger;
    if (!reduxTags || reduxTags.length === 0) {
      return;
    }

    const getDraftNote = async () => {
      try {
        debugger;
        const draftNote = JSON.parse(localStorage.getItem('draftNote'));
        return draftNote;
      } catch (error) {
        console.error('Error writing file:', error);
        alert('Error writing file: ' + error);
      }
      return "";
    };

    getDraftNote().then(note => {
      console.log(JSON.stringify(note));
      debugger;
      setContent(note.content);

      const updatedTags = reduxTags.map(tag => ({
        ...tag,
        active: note.selectedTags.includes(tag.tagId)
      }));
      setLocalTags(updatedTags);
    });
  }, [reduxTags]);

  const postDraftNote = async () => {
    // Only post if there are changes
    debugger;
    if (lastSavedDraft !== content) {
      const selectedTags = getSelectedTagIds();
      const draftNote = { content: content, tagIds: selectedTags, variant: "personal" };
      await updateNoteDraft(api, draftNote);
    }
  };

  const postTasks = async (taskNames, status) => {
    await createTasks(api, taskNames, getSelectedTagIds(), status).then(() => {
      clearDraft();
      queryClient.invalidateQueries(Util.config.taskQueryKey);
    }
    );
  };

  useEffect(() => {
    // Clear timeout on component unmount
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const handleButtonToggle = (tagId) => {
    ;
    const updatedTags = localTags.map(tag =>
      tag.tagId === tagId ? { ...tag, active: !tag.active } : tag
    );
    setLocalTags(updatedTags);
  };

  const handleEditableButtonUpdate = async (createTagObject) => {
    try {
      if (createTagObject === "") { return; }
      debugger;
      var newTags = await createTag(api, createTagObject);
      var updatedTags = [...localTags];
      debugger;
      newTags.data.map(tag => {
        // Create a new object with specific properties
        var newTag = {
          tagId: tag.tagId,
          name: tag.name,
          color: tag.color,
          variant: "personal"
        };
        updatedTags.push(newTag);
        dispatch(addTag(newTag));
      });
      setLocalTags(updatedTags);
    } catch (error) {
      console.error('Error writing file:', error);
      alert('Error writing file: ' + error);
    }
  };

  const updateNoteContent = async (event) => {
    setContent(event.target.value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout to post the note after 2 seconds of inactivity
    const id = setTimeout(() => {
      postDraftNote();
    }, 500); // Adjust the duration as needed

    setTimeoutId(id);
  }

  const getSelectedTagIds = () => {
    const activeTags = localTags.filter(tag => tag.active);
    const tagIds = activeTags.map(tag => tag.tagId);
    return tagIds;
  }

  const mergeNote = async () => {
    try {
      const response = await createNote(api, content, getSelectedTagIds());
      clearDraft();
      var createdNote = {noteId: response.recordId,content: content, tags: localTags.filter(tag => tag.active), variant: "personal",createdDate: Util.getCurrentDateExtended()};
      queryClient.setQueryData(Util.config.notesQueryKey, (oldData) => {
        return [...oldData, createdNote];
      });
    } catch (error) {
      console.error('Error merging note:', error);
      alert('Error merging note: ' + error);
    }
  };

  const saveToList = async (status) => {
    try {
      if (content === undefined) return;
      const contentArray = content.split('\n');
      const newTasks = contentArray.map(name => ({ "name": name, "tags": getSelectedTagIds(), status: status, variant: "personal" }));
      const newTasksWithTagDetails = contentArray.map(name => ({ "name": name, "tags": localTags.filter(tag => tag.active), status: status, variant: "personal" }));

      const taskData = queryClient.getQueryData(Util.config.taskQueryKey);

      if (taskData) {
        if (status === 'Active') {
          queryClient.setQueryData(Util.config.taskQueryKey, (oldData) => {
            return {
              activeTasks: [...oldData.activeTasks, ...newTasksWithTagDetails],
              completedTasks: [...oldData.completedTasks],
              backlogTasks: [...oldData.backlogTasks]
            };
          });
        } else if (status === 'Backlog') {
          queryClient.setQueryData(Util.config.taskQueryKey, (oldData) => {
            return {
              activeTasks: [...oldData.activeTasks],
              completedTasks: [...oldData.completedTasks],
              backlogTasks: [...oldData.backlogTasks, ...newTasksWithTagDetails]
            };
          });
        }
      }
      setContent('');

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout to post the note after 2 seconds of inactivity
      const id = setTimeout(() => {
        postTasks(contentArray, status);
      }, 500); // Adjust the duration as needed

      setTimeoutId(id);
    } catch (error) {
      console.error('Error saving list item:', error);
      alert('Error saving list item: ' + error);
    }
  };

  const clearDraft = async () => {
    try {
      const draftNote = { content: '', tagIds: getSelectedTagIds(), variant: "personal" };
      await updateNoteDraft(api, draftNote);
      setContent('');
      localStorage.setItem('draftNote', JSON.stringify({ content: '', selectedTags: getSelectedTagIds() }));
    } catch (error) {
      console.error('Error clearing draft note:', error);
      alert('Error clearing draft note: ' + error);
    }
  }

  useEffect(() => {
    const saveDraft = async () => {
      const selectedTags = getSelectedTagIds();
      localStorage.setItem('draftNote', JSON.stringify({ content, selectedTags }));
    };

    saveDraft();
  }, [content, localTags]);

  const buttonClassNames = "font-bold text-lg bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mx-2 inline-flex items-center mt-6";

  return (
    <div>
      <NotesComponent content={content} handleInputChange={updateNoteContent} />
      <TagsComponent tags={localTags} includeEditButton={true} handleButtonToggle={handleButtonToggle} handleEditableButtonUpdate={handleEditableButtonUpdate} />

      <button id="merge-note" onClick={mergeNote} className={buttonClassNames}>Complete</button>
      <button id="save-todo" onClick={() => saveToList("Active")} className={buttonClassNames}>Save To Tasks</button>
      <button id="save-backlog" onClick={() => saveToList("Backlog")} className={buttonClassNames}>Save To Backlog</button>
    </div>
  );
};

export default WritePage;