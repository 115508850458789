import React from 'react';
import ToggleButton from '../elements/ToggleButton';
import EditableButton from '../EditableButton';

interface Tag {
  tagId: string;
  name: string;
  color?: string;
  active?: boolean;
  variant?: string;
}

interface TagsComponentProps {
  tags: Tag[];
  handleButtonToggle: (tagId: string) => void;
  handleEditableButtonUpdate: (createTagObject: any) => void;
  includeEditButton?: boolean;
}

const TagsComponent: React.FC<TagsComponentProps> = (props) => {

  const handleButtonToggle = (value) => {
    props.handleButtonToggle(value);
  };

  const handleEditableButtonUpdate = (value: string) => {
    if (!value.trim()) return;
    
    // Create the tag object and pass it to the parent handler
    const newTag = {
      names: [value],
      variant: "personal",
      color: "green"
    };
    props.handleEditableButtonUpdate(newTag);
  };

  if (!props.tags) {
    return (<div>Loading tags</div>)
  }

  return (
    <div>
      <div className="flex flex-wrap">
        {props.tags.map((tag) => (
          <ToggleButton
            key={tag.tagId}
            tagId={tag.tagId}
            name={tag.name}
            color='blue'
            isToggled={tag.active}
            handleButtonToggle={props.handleButtonToggle}
          />
        ))}
        {props.includeEditButton ? (
          <div onClick={(e) => e.stopPropagation()}>
            <EditableButton 
              text="+" 
              onUpdate={handleEditableButtonUpdate}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TagsComponent;