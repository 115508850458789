import React, { useState } from 'react';

const EditableButton = ({ text, size = 'small', onUpdate }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [textValue, setTextValue] = useState(text);
    const [editingText, setEditingText] = useState("");

    const handleEditClick = () => {
        setIsEditing(true);
    }

    const handleInputChange = (e) => {
        setEditingText(e.target.value);
    }

    const handleInputBlur = (e) => {
        setIsEditing(false);
        setEditingText("");
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onUpdate(editingText);
            setIsEditing(false);
            setEditingText("");
        }
    };

    // const getSizeStyle = () => {
    //     switch (size) {
    //         case 'small':
    //             return { padding: '8px 16px', fontSize: '14px' };
    //         case 'large':
    //             return { width: '100%', padding: '16px 32px', fontSize: '20px', display: 'inline-block' };
    //         case 'medium':
    //         default:
    //             return { padding: '12px 24px', fontSize: '16px' };
    //     }
    // };
    // const buttonStyle = {
    //     ...getSizeStyle()
    // };
    const containerStyle = {
        width: '100 %'
    };

    const className = `button bg-slate-500 text-white font-bold py-2 px-4 items-center rounded-full`

    return (
        <div style={containerStyle}>
            {isEditing ? (
                <input
                    type="text"
                    value={editingText}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onKeyDown={handleKeyDown}
                    autoFocus
                    className={className}
                    contentEditable={isEditing}
                // style={buttonStyle}
                />
            ) : (
                <button
                    onClick={handleEditClick}
                    className={className}
                // style={buttonStyle}
                >{textValue}</button>
            )
            }
        </div>
    )
}

export default EditableButton;