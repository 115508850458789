import React, { useEffect, useState } from 'react';
import './elements.css';
import { getHabitsSchedule, updateUserSchedule } from '../api';
import { useApiClient } from '../../service/useApiClient';

const CheckmarkTable = (props) => {
  const api = useApiClient();
  const [schedule, setSchedule] = useState({});

  useEffect(() => {
    async function getSchedule() {
      try {
        const result = await getHabitsSchedule(api);
        setSchedule(result.data);
      } catch (error) {
        console.error('Error getting file:', error);
        alert('Error getting file');
      }
    }
    getSchedule();
  }, []);

  const handleCellClick = async (habit, dayOfWeek) => {
    let updatedSchedule = { ...schedule };

    let daySchedule = updatedSchedule[dayOfWeek];
    if (daySchedule != null) {
      const scheduleIndex = daySchedule.indexOf(habit);

      if (scheduleIndex == -1) {
        daySchedule.push(habit);
      } else {
        daySchedule.splice(scheduleIndex, 1);
      }
    } else {
      daySchedule = [];
      daySchedule.push(habit);
    }

    updatedSchedule[dayOfWeek] = daySchedule;

    try {
      let payload = {
        variant: "personal",
        [dayOfWeek]: daySchedule
      };

      await updateUserSchedule(api, payload)
    } catch (error) {
      console.error('Error writing file:', error);
      alert('Error writing file');
    }
    setSchedule(updatedSchedule);
  }

  const isCellChecked = (rowIndex, columnIndex) => {
    let isChecked = false;
    const daySchedule = schedule[columnIndex];
    if (daySchedule) {
      isChecked = daySchedule.includes(rowIndex);
    }
    return isChecked;
  };

  const tableId = "checkMarkTable-" + props.tableName;

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]

  return (
    <table id={tableId}>
      <thead>
        <tr>
          <th></th> {/* Empty cell for row headers */}
          {daysOfWeek.map((item, index) => (
            <th key={index}>{item}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.habits && props.habits.map(task => (
          <tr key={task.taskId}>
            <th>{task.name}</th> {/* Row headers */}
            {daysOfWeek.map(cellIndex => (
              <td className="day"
                key={cellIndex.toLowerCase()}
                onClick={() => handleCellClick(task.taskId, cellIndex.toLowerCase())}
                style={{
                  cursor: 'pointer',
                  backgroundColor: isCellChecked(task.taskId, cellIndex.toLowerCase()) ? 'lightblue' : 'white',
                  textAlign: 'center' // Center align content
                }}
              >
                <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
                  {isCellChecked(task.taskId, cellIndex.toLowerCase()) ? '✔' : ''}
                </span>
              </td>
            ))}
          </tr>
        ))}
        {/* <tr><th></th><td colSpan={7}><EditableButton size="large" text="+" onUpdate={undefined}></EditableButton></td></tr> */}
      </tbody>
    </table>
  );
};

export default CheckmarkTable;
