import React from 'react';
import '../elements/elements.css';
import styled from 'styled-components';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page H',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page I',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page J',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page K',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page L',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page M',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page N',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page O',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page P',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page Q',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page R',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page S',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page T',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page U',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
const tasks = [
  {
    name: "Write Report",
    schedule: [
      { day: "Mon", isComplete: true },
      { day: "Tue", isComplete: true },
      { day: "Fri", isComplete: false }
    ]
  },
  {
    name: "Team Meeting",
    schedule: [
      { day: "Wed", isComplete: false }
    ]
  },
  {
    name: "Code Review",
    schedule: [
      { day: "Mon", isComplete: true },
      { day: "Thu", isComplete: true }
    ]
  },
  {
    name: "Design Workshop",
    schedule: [
      { day: "Tue", isComplete: false },
      { day: "Thu", isComplete: false }
    ]
  },
  {
    name: "Client Call",
    schedule: [
      { day: "Fri", isComplete: true }
    ]
  }
];

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function ScheduleTable() {
  function isDayComplete(day) {
    return tasks.every(task => {
      const scheduleItem = task.schedule.find(s => s.day === day);
      return scheduleItem ? scheduleItem.isComplete : true;
    });
  }

  return (
    <table style={{ width: "100%", borderCollapse: "collapse" }}>
      <thead>
        <tr>
          <th style={{ textAlign: "left", padding: "8px" }}>Task</th>
          {daysOfWeek.map(day => (
            <th key={day} style={{ padding: "8px", textAlign: "center" }}>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tasks.map(task => (
          <tr key={task.name}>
            <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{task.name}</td>
            {daysOfWeek.map(day => {
              const scheduleItem = task.schedule.find(s => s.day === day);
              return (
                <td key={day} style={{ padding: "8px", textAlign: "center", borderBottom: "1px solid #ddd" }}>
                  {scheduleItem ? (
                    <span
                      style={{
                        display: "inline-block",
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        backgroundColor: scheduleItem.isComplete ? "#54c45e" : "#D3D3D3",  // Light Kelly Green if complete
                        border: scheduleItem.isComplete ? "1px solid black" : "1px solid #AAA",
                        // boxShadow: scheduleItem.isComplete ? "0px 0px 5px #5A9216" : "0px 0px 5px #AAA",
                        transition: "background-color 0.3s ease"
                      }}
                    ></span>
                  ) : null}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          {daysOfWeek.map(day => (
            <td key={day} style={{ padding: "8px", textAlign: "center", fontSize: "20px" }}>
              {isDayComplete(day) ? (
                <span
                  style={{
                    color: "#ffe342",        // Fill with yellow
                    fontWeight: "bold",     // Make it bold
                    textShadow: "0px 0px 2px black",  // Outline effect
                    WebkitTextStroke: "1px black", // Black outline
                    fontSize: "30px"
                  }}
                >
                  ★
                </span>
              ) : ""}
            </td>
          ))}
        </tr>
      </tfoot>
    </table>
  );
}

const DashboardContainer = styled.div`
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const DashboardBlock = styled.div`
  background: ${props => props.bgColor || '#ffffff'};
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  height: ${props => props.height || '300px'};
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  h2 {
    margin: 0 0 1.5rem 0;
    color: #333;
    font-size: 1.5rem;
    font-weight: 600;
  }

  p {
    color: #555;
    line-height: 1.6;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }

  .content-area {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
  }

  .placeholder-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: ${props => props.iconColor || props.bgColor};
    opacity: 0.7;
  }
`;

const ChartBlock = styled(DashboardBlock)`
  overflow-x: auto;
  max-height: 400px;
  grid-column: span 1;
  
  .chart-container {
    min-width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1200px) {
    grid-column: span 1;
  }
`;

const ScheduleBlock = styled(DashboardBlock)`
  grid-column: span 2;
  max-height: 400px;
  overflow-y: auto;

  table {
    font-size: 0.9em;
  }

  th, td {
    padding: 6px !important;
  }
`;

const Dashboard = () => {
  const pieData = [
    { name: 'Tasks Completed', value: 35, color: '#ff9999' },
    { name: 'In Progress', value: 45, color: '#ff6666' },
    { name: 'Pending', value: 20, color: '#ff3333' }
  ];

  return (
    <DashboardContainer>
      <ScheduleBlock bgColor="#f0f7ff" height="auto">
        <h2>Weekly Schedule</h2>
        <ScheduleTable />
      </ScheduleBlock>
      
      <ChartBlock bgColor="#fff5f5" height="auto">
        <h2>Activity Overview</h2>
        <div className="chart-container">
          <PieChart width={300} height={300}>
            <Pie
              data={pieData}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              paddingAngle={5}
              dataKey="value"
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </div>
      </ChartBlock>

      <DashboardBlock bgColor="#f0fff4" iconColor="#38a169">
        <h2>Quick Notes</h2>
        <div className="content-area">
          <div className="placeholder-icon">📝</div>
          <p>Recent notes and quick access to create new ones will appear here.</p>
          <p>Click to start writing a new note or view your recent entries.</p>
        </div>
      </DashboardBlock>

      <DashboardBlock bgColor="#fff0f7" iconColor="#d53f8c">
        <h2>Upcoming Tasks</h2>
        <div className="content-area">
          <div className="placeholder-icon">✓</div>
          <p>Your most important upcoming tasks will be displayed in this section.</p>
          <p>Prioritize and manage your daily activities efficiently.</p>
        </div>
      </DashboardBlock>

      <DashboardBlock bgColor="#f3f0ff" iconColor="#6b46c1">
        <h2>Progress Tracker</h2>
        <div className="content-area">
          <div className="placeholder-icon">📊</div>
          <p>Track your goals and daily achievements in this block.</p>
          <p>Visualize your progress and stay motivated.</p>
        </div>
      </DashboardBlock>
    </DashboardContainer>
  );
};

export default Dashboard;