import React, { useState } from 'react';
import styles from './ListManagement.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { addTag, deleteTag as removeTag } from '../../slices/tagsSlice';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useApiClient } from '../../service/useApiClient';
import { createTag, deleteTags } from '../api';

function TagsPage() {
  const [newTagName, setNewTagName] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingValue, setEditingValue] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);
  const globalTags = useSelector((state) => state.tags.items);
  const dispatch = useDispatch();
  const [tagsToAdd, setTagsToAdd] = useState([]);
  const [tagsToDelete, setTagsToDelete] = useState([]);
  const queryClient = useQueryClient();
  const api = useApiClient();

  const addTags = async () => {
    if (tagsToAdd && tagsToAdd.length > 0) {
      const newTagsRequest = {
        "names": tagsToAdd,
        "variant": "personal",
        "color": "green"
      };

      await createTag(api, newTagsRequest);
      setTagsToAdd([]); // Clear the list after sending
    }
  };

  const handleDeleteTags = async () => {
    debugger;
    if (tagsToDelete && tagsToDelete.length > 0) {
      await deleteTags(api, tagsToDelete);
      setTagsToDelete([]); // Clear the list after sending
    }
  };
  const queryKey = ['tags', { variant: 'personal' }];

  const createTagMutation = useMutation({
    mutationFn: addTags,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  const deleteTagMutation = useMutation({
    mutationFn: handleDeleteTags,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  const handleAddTag = async () => {
    try {

      setTagsToAdd((prev) => [...prev, newTagName]);
      const tagData = queryClient.getQueryData(queryKey);

      if (tagData) {
        queryClient.setQueryData(queryKey, (oldData) => {
          const newData = [...oldData]
          return newData;
        });
      }
      const newTag = {
        name: newTagName,
        color: 'green'
      }
      dispatch(addTag(newTag));
      setNewTagName('');

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout to archive the task after 2 seconds of inactivity
      const id = setTimeout(() => {
        createTagMutation.mutate();
      }, 2000); // Adjust the duration as needed

      setTimeoutId(id);
    } catch (error) {
      // ;
      console.error('Error adding item:', error);
    }
  };

  const handleEdit = async (index) => {
    // try {
    //   const response = await axios.put(`/api/items/${items[index].id}`, { item: editingValue });
    //   const updatedItems = [...items];
    //   updatedItems[index] = response.data;
    //   setItems(updatedItems);
    //   setEditingIndex(null);
    //   setEditingValue('');
    // } catch (error) {
    //   console.error('Error editing item:', error);
    // }
  };

  const handleDelete = async (tagId) => {
    try {
      dispatch(removeTag(tagId));

      const tagData = queryClient.getQueryData(queryKey);

      if (tagData) {
        queryClient.setQueryData(queryKey, (oldData) => {
          const newData = oldData.filter(tag => tag.tagId !== tagId);
          return newData;
        });
      }
      setTagsToDelete((prev) => [...prev, tagId]);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const id = setTimeout(() => {
        deleteTagMutation.mutate();
      }, 2000); // Adjust the duration as needed

      setTimeoutId(id);
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Tags</h1>

      <ul className={styles.list}>
        {globalTags.map((item, index) => (
          <li key={item.tagId} className={styles.listItem}>
            {editingIndex === index ? (
              <>
                <input
                  type="text"
                  value={editingValue}
                  onChange={(e) => setEditingValue(e.target.value)}
                  className={styles.input}
                />
                <div className={styles.actionButtons}>
                  <button onClick={() => handleEdit(index)} className={styles.button}>Save</button>
                  <button onClick={() => setEditingIndex(null)} className={styles.button}>Cancel</button>
                </div>
              </>
            ) : (
              <>
                {item.name}
                <div className={styles.actionButtons}>
                  <button onClick={() => { setEditingIndex(index); setEditingValue(item.name); }} className={styles.button}>
                    Edit
                  </button>
                  <button onClick={() => handleDelete(item.tagId)} className={styles.button}>Delete</button>
                </div>
              </>
            )}
          </li>
        ))}
      </ul>

      <input
        type="text"
        value={newTagName}
        onChange={(e) => setNewTagName(e.target.value)}
        placeholder="Add new item"
        className={styles.input}
      />
      <button onClick={handleAddTag} className={styles.button}>Add</button>
    </div>
  );
}

export default TagsPage;