// store.ts
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import tagsReducer from './slices/tagsSlice';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
    key: 'root', // key for the persisted state
    storage, // storage engine (localStorage)
};

const rootReducer = combineReducers({
    tags: tagsReducer, // Ensure this matches your state structure
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production'
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };