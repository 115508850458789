// tagsSlice.ts
import { createSlice } from '@reduxjs/toolkit';

interface Tag {
    tagId: string;
    name: string;
    active: boolean;
}

interface TagsState {
    items: Tag[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: TagsState = {
    items: [],
    status: 'idle',
    error: null,
};

const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        toggleTagActive: (state, action) => {
            const tagId = action.payload;
            const tag = state.items.find(tag => tag.tagId === tagId); if (tag) {
                tag.active = !tag.active; // Toggle the 'active' status
            }
        },
        setTags: (state, action) => {      // Setting items to a new array     
            state.items = action.payload; // This should work correctly    
        },
        addTag: (state, action) => {
            debugger;
            const newTags = [...state.items, action.payload];
            state.items = newTags;
        },
        deleteTag: (state, action) => {
            state.items = state.items.filter(tag => tag.tagId !== action.payload);
        },
    },
    extraReducers: (builder) => {
        // builder
        //     .addCase(fetchTags.pending, (state) => {
        //         state.status = 'loading';
        //     })
        //     .addCase(fetchTags.fulfilled, (state, action) => {
        //         state.status = 'succeeded';
        //         state.items = action.payload; // Tags now have 'active' status
        //     })
        //     .addCase(fetchTags.rejected, (state, action) => {
        //         state.status = 'failed';
        //         state.error = action.error.message;
        //     });

        // builder
        //     .addCase(createTag.pending, (state) => {
        //         state.status = 'loading';
        //     })
        //     .addCase(createTag.fulfilled, (state, action) => {
        //         state.status = 'succeeded';
        //         state.items.push(action.payload);
        //     })
        //     .addCase(createTag.rejected, (state, action) => {
        //         state.status = 'failed';
        //         state.error = action.error.message;
        //     });

        // builder
        //     .addCase(deleteTag.pending, (state) => {
        //         state.status = 'loading';
        //     })
        //     .addCase(deleteTag.fulfilled, (state, action) => {
        //         state.status = 'succeeded';
        //         state.items = state.items.filter(tag => tag.tagId !== action.payload);
        //     })
        //     .addCase(deleteTag.rejected, (state, action) => {
        //         state.status = 'failed';
        //         state.error = action.error.message;
        //     });
    },
});

export const { toggleTagActive, addTag, deleteTag, setTags } = tagsSlice.actions;
export default tagsSlice.reducer;