import React from 'react';
import './elements.css';
const Entry = (props) => {
    const { entry } = props;
    const tagClass = `button bg-blue-500 text-white font-bold text-sm py-2 px-4 items-center rounded-full flex-item`

    return (
        <div className="entry">
            <div className="entryDate">
                <h2>{entry.createdDate.formattedDate}</h2>
            </div>
            <p>{entry.content}</p>
            {entry.tags.map((tag, index) => (
                <button className={tagClass} key={index}>
                    {tag.name}
                </button>
            ))}
        </div>
    );
}

export default Entry;