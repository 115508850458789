import React, { useState } from 'react';
import Checklist from '../elements/Checklist';
import Util from '../Util';
import ReadTagsComponent from '../elements/ReadTagsComponent';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useAuth0 } from "@auth0/auth0-react";
import { completeTasks, activateTasks, updateHabitStatuses, getTasks, getActiveHabits } from '../api';
import { useApiClient } from '../../service/useApiClient';

const fetchTasks = async (api) => {
    const response = await getTasks(api);
    return response.data;
};

const fetchHabits = async (api) => {
    const response = await getActiveHabits(api);

    const combinedList = [
        ...response.data.incompleteHabits.map(task => ({ ...task, isComplete: false })),   // Mark IDs from listA as isComplete: true
        ...response.data.completedHabits.map(task => ({ ...task, isComplete: true }))   // Mark IDs from listB as isComplete: false
    ];
    debugger;
    combinedList.sort((a, b) => a.isComplete - b.isComplete);
    return combinedList;
};

const TrackingPage = (props) => {
    const [completeTaskTimeoutId, setCompleteTaskTimeoutId] = useState(null);
    const [activateTaskTimeoutId, setActivateTaskTimeoutId] = useState(null);
    const [completeHabitTimeoutId, setCompleteHabitTimeoutId] = useState(null);
    const queryClient = useQueryClient();
    const [taskIdsToComplete, setTaskIdsToComplete] = useState([]);
    const [taskIdsToActivate, setTaskIdsToActivate] = useState([]);
    const [completedHabitIds, setCompletedHabitIds] = useState([]);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const api = useApiClient();

    const updateTaskStatuses = async () => {
        debugger;
        if (taskIdsToComplete.length > 0) {
            await completeTasks(api, taskIdsToComplete);
            setTaskIdsToComplete([]);
        }
        if (taskIdsToActivate.length > 0) {
            await activateTasks(api, taskIdsToActivate);
            setTaskIdsToActivate([]);
        }
    }

    const updateTaskStatusesMutation = useMutation({
        mutationFn: async () => {
            return updateTaskStatuses();
        },
        enabled: isAuthenticated, // Ensure API call only happens when authenticated
        staleTime: 5 * 60 * 1000, // Keep tags fresh for 5 minutes,
        onSuccess: () => {
            queryClient.invalidateQueries(Util.config.taskQueryKey);
        },
    });

    const updateCompletedHabitsMutation = useMutation({
        mutationFn: async () => {
            return updateHabitStatuses(api, completedHabitIds);
        },
        enabled: isAuthenticated, // Ensure API call only happens when authenticated
        staleTime: 5 * 60 * 1000, // Keep tags fresh for 5 minutes, () => updateHabitStatuses(),
        onSuccess: () => {
            queryClient.invalidateQueries(Util.config.habitsQueryKey);
        },
    });

    const { data: taskData } = useQuery({
        queryKey: Util.config.taskQueryKey,
        queryFn: async () => {
            return fetchTasks(api);
        },
        enabled: isAuthenticated, // Ensure API call only happens when authenticated
        staleTime: 5 * 60 * 1000, // Keep tags fresh for 5 minutes
    });

    const { data: habitsData } = useQuery({
        queryKey: Util.config.habitsQueryKey,
        queryFn: async () => {
            return fetchHabits(api);
        },
        enabled: isAuthenticated, // Ensure API call only happens when authenticated
        staleTime: 5 * 60 * 1000, // Keep tags fresh for 5 minutes
    });

    const handleCompleteHabit = async (habit) => {
        debugger;

        const isHabitCompleted = completedHabitIds.some(id => id === habit.taskId);

        if (isHabitCompleted) {
            debugger;
            setCompletedHabitIds(prevCompletedHabitIds =>
                prevCompletedHabitIds.filter(id => id !== habit.taskId)
            );
        } else {
            setCompletedHabitIds((prev) => [...prev, habit.taskId]);
        }
        queryClient.setQueryData(Util.config.habitsQueryKey, (oldData) => {
            const updatedList = oldData.map(prev =>
                prev.taskId === habit.taskId
                    ? { ...prev, isComplete: !prev.isComplete }  // Toggle isComplete
                    : prev
            );
            updatedList.sort((a, b) => a.isComplete - b.isComplete);
            return updatedList;
        });

        if (completeHabitTimeoutId) {
            clearTimeout(completeHabitTimeoutId);
        }

        const id = setTimeout(() => {
            debugger;
            updateCompletedHabitsMutation.mutate();
        }, 200);

        setCompleteHabitTimeoutId(id);
    };

    const handleCompleteTask = async (completedTask) => {
        // debugger;
        if (taskIdsToComplete.includes(completedTask.taskId)) return; // Prevent duplicates
        const taskToComplete = taskData.activeTasks.find(task => task.taskId === completedTask.taskId);
        if (!taskToComplete) return; // Exit if the task is not found

        // Optimistically update the UI (this assumes taskData has two separate arrays)
        queryClient.setQueryData(Util.config.taskQueryKey, (oldData) => {
            return {
                activeTasks: oldData.activeTasks.filter(task => task.taskId !== completedTask.taskId),
                completedTasks: [...oldData.completedTasks, { ...taskToComplete, completed: true }],
                backlogTasks: [...oldData.backlogTasks]
            };
        });
        setTaskIdsToComplete((prev) => [...prev, completedTask.taskId]);

        if (completeTaskTimeoutId) {
            clearTimeout(completeTaskTimeoutId);
        }

        const id = setTimeout(() => {
            debugger;
            updateTaskStatusesMutation.mutate();
        }, 200);

        setCompleteTaskTimeoutId(id);
    };

    const handleActivateTaskFromBacklog = async (activatedTask) => {
        debugger;
        await activateTask(activatedTask, 'backlog');
    }
    const handleActivateTaskFromCompleted = async (activatedTask) => {
        await activateTask(activatedTask, 'complete');
    }


    const activateTask = async (activatedTask, source) => {
        // debugger;
        if (taskIdsToActivate.includes(activatedTask.taskId)) return; // Prevent duplicates
        var taskToActivate;

        if (source === 'backlog') {
            taskToActivate = taskData.backlogTasks.find(task => task.taskId === activatedTask.taskId);
        } else if (source === 'complete') {
            taskToActivate = taskData.completedTasks.find(task => task.taskId === activatedTask.taskId);
        }

        if (!taskToActivate) return; // Exit if the task is not found

        // Optimistically update the UI (this assumes taskData has two separate arrays)
        queryClient.setQueryData(Util.config.taskQueryKey, (oldData) => {
            var activeTasks = [...oldData.activeTasks, { ...taskToActivate, completed: false }];
            var backlogTasks;
            var completedTasks;
            if (source === 'backlog') {
                backlogTasks = oldData.backlogTasks.filter(task => task.taskId !== activatedTask.taskId);
                completedTasks = [...oldData.completedTasks];
            } else if (source === 'complete') {
                completedTasks = oldData.completedTasks.filter(task => task.taskId !== activatedTask.taskId);
                backlogTasks = [...oldData.backlogTasks];
            }
            return {
                completedTasks: completedTasks,
                activeTasks: activeTasks,
                backlogTasks: backlogTasks
            };
        });
        setTaskIdsToActivate((prev) => [...prev, activatedTask.taskId]);

        if (activateTaskTimeoutId) {
            clearTimeout(activateTaskTimeoutId);
        }

        const id = setTimeout(() => {
            debugger;
            updateTaskStatusesMutation.mutate();
        }, 200);

        setActivateTaskTimeoutId(id);
    };



    // const handleActivateTask = async (activatedTask) => {
    //     // debugger;
    //     if (taskIdsToActivate.includes(activatedTask.taskId)) return; // Prevent duplicates
    //     const taskToActivate = taskData.completedTasks.find(task => task.taskId === activatedTask.taskId);
    //     if (!taskToActivate) return; // Exit if the task is not found

    //     // Optimistically update the UI (this assumes taskData has two separate arrays)
    //     queryClient.setQueryData(Util.config.taskQueryKey, (oldData) => {
    //         return {
    //             completedTasks: oldData.completedTasks.filter(task => task.taskId !== activatedTask.taskId),
    //             activeTasks: [...oldData.activeTasks, { ...taskToActivate, completed: false }],
    //             backlogTasks: [...oldData.backlogTasks]
    //         };
    //     });
    //     setTaskIdsToActivate((prev) => [...prev, activatedTask.taskId]);

    //     if (activateTaskTimeoutId) {
    //         clearTimeout(activateTaskTimeoutId);
    //     }

    //     const id = setTimeout(() => {
    //         debugger;
    //         activateTasksMutation.mutate();
    //     }, 2000);

    //     setActivateTaskTimeoutId(id);
    // };

    return (
        <div>
            <ReadTagsComponent />
            {taskData && habitsData && (
                <div>
                    <div className="flex">
                        <Checklist title="Habits" handleItemToggle={handleCompleteHabit} items={habitsData} />
                        <Checklist
                            title="Active Tasks"
                            handleItemToggle={handleCompleteTask}
                            items={taskData.activeTasks}
                        />
                    </div>
                    <div className="flex">
                        <Checklist title="Backlog" handleItemToggle={handleActivateTaskFromBacklog} items={taskData.backlogTasks} />
                        <Checklist isComplete={true} title="Completed" handleItemToggle={handleActivateTaskFromCompleted} items={taskData.completedTasks} />
                    </div></div>
            )}
        </div>
    );
};

export default TrackingPage;