import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import '../../../output.css';
import Util from '../../Util';

const NavContainer = styled.div`
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
`;

const StyledNavLink = styled(NavLink)`
  color: #333;
  text-decoration: none;
  padding: 0.5rem 1.25rem;
  font-size: 0.95rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  transition: all 0.2s ease;
  position: relative;
  margin: 0 0.25rem;

  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 1.25rem;
    right: 1.25rem;
    height: 1px;
    background: rgba(0, 0, 0, 0.2);
    transform: scaleX(0);
    transition: transform 0.2s ease;
  }

  &:hover {
    color: #000;
  }

  &.active {
    color: #333;
    background: none !important;
    &:after {
      transform: scaleX(1);
    }
  }
`;

const MenuItem = ({ to, children }) => (
  <StyledNavLink
    to={to}
    className={({ isActive }) => isActive ? 'active' : ''}
  >
    {children}
  </StyledNavLink>
);

const Navigation = () => {
  return (
    <NavContainer>
      <nav className="flex justify-center">
        <MenuItem to="/dashboard" end>
          Home
        </MenuItem>
        <MenuItem to="/write">
          Write
        </MenuItem>
        <MenuItem to="/read">
          Read
        </MenuItem>
        <MenuItem to="/track">
          Track
        </MenuItem>
        <MenuItem to="/admin">
          Admin
        </MenuItem>
      </nav>
    </NavContainer>
  );
}

export default Navigation;