import React, { useMemo } from 'react';
import Util from '../Util';
import ReadTagsComponent from '../elements/ReadTagsComponent';
import ScrollDetector from '../elements/ScrollDetectorObject'
import { selectActiveTags } from '../../slices/selectors';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from "@auth0/auth0-react";
import { getNotes } from '../api';
import { useApiClient } from '../../service/useApiClient';

const fetchNotes = async (api) => {
  var response = await getNotes(api);
  return response.data;
};

const ReadPage = () => {
  const { isAuthenticated } = useAuth0();

  const activeTags = useSelector(selectActiveTags);
  const api = useApiClient();

  const { data: notesData, notesError, notesAreLoading } = useQuery({
    queryKey: Util.config.notesQueryKey,
    queryFn: async () => {
      return fetchNotes(api);
    },
    enabled: isAuthenticated,
    staleTime: 5 * 60 * 1000,
  });

  const filteredNotes = useMemo(() => {
    debugger;
    if (!notesData) return [];
    if (activeTags.length === 0) return notesData;

    return notesData.filter(note =>
      activeTags.every(activeTag =>
        note.tags.some(noteTag => noteTag.tagId === activeTag.tagId)
      )
    );
  }, [notesData, activeTags]);

  if (notesAreLoading) {
    return <div>Loading...</div>;
  }

  if (notesError) {
    return <div>Error loading notes</div>;
  }

  return (
    <div>
      <ReadTagsComponent />
      {filteredNotes && <ScrollDetector journal={filteredNotes} />}
    </div >
  );
};

export default ReadPage;