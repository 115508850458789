// src/components/Checklist.js
import React, { useEffect, useState } from 'react';
import ChecklistItem from './ChecklistItem';
import { useSelector } from 'react-redux';
import { selectActiveTags } from '../../slices/selectors';

const Checklist = (props) => {
    const activeTags = useSelector(selectActiveTags);
    const [items, setItems] = useState([]);

    const handleToggle = (toggledItem) => {
        props.handleItemToggle(toggledItem);
    };

    useEffect(() => {
        if (props.items) {
            const filteredTodos = props.items.filter(item =>
                activeTags.every(activeTag =>
                    item.tags.some(tag => tag.tagId === activeTag.tagId)
                )
            );
            setItems(filteredTodos);
        }
    }, [props.items, activeTags]);

    return (
        <div className="m-3 rounded p-4 border-2 shadow w-3/4 h-[32rem] overflow-auto">
            <h2 className="text-lg font-thin text-center mb-4">{props.title}</h2>
            <ul className="text-left list-none">
                {items.map(item => (
                    <ChecklistItem isComplete={props.isComplete ? props.isComplete : item.isComplete} item={item} handleToggle={handleToggle} />
                ))}
            </ul>
        </div>
    );
};

export default Checklist;