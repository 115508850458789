import React, { useEffect, useState } from 'react';
import './elements.css';
import EntryList from './EntryList';

const ScrollDetectorObject = (props) => {
    const { journal } = props;

    const [nextElementIndex, setNextElementIndex] = useState(1);
    const [elements, setElements] = useState<HTMLCollectionOf<Element>>();
    const [currentFloatingElement, setCurrentFloatingElement] = useState<Element>();
    const [scrollPosition, setScrollPosition] = useState(window.scrollY);

    useEffect(() => {
        const elements = document.getElementsByClassName('entryDate');
        if (elements.length > 0) {
            setElements(elements);
            setCurrentFloatingElement(elements[0]);
        }
    }, [journal]); // Re-run when journal changes

    useEffect(() => {
        const handleScroll = () => {
            const container = document.getElementById('container');
            if (container !== undefined && elements && elements.length > 0) {
                const containerRect = container.getBoundingClientRect();
                if (elements[nextElementIndex] === undefined) {
                    setCurrentFloatingElement(elements[0]);
                    setNextElementIndex(1);
                }

                if (elements[nextElementIndex] != null) {
                    const nextElementRect = elements[nextElementIndex].getBoundingClientRect();
                    const currentElementRect = currentFloatingElement.getBoundingClientRect();

                    setScrollPosition(window.scrollY);
                    const isAtTopOfContainer = containerRect.top >= nextElementRect.top;

                    if (isAtTopOfContainer) {
                        setCurrentFloatingElement(elements[nextElementIndex]);
                        if (nextElementIndex < elements.length - 1) {
                            setNextElementIndex(nextElementIndex + 1);
                        }
                    } else {
                        const currentElementIsAtTop = containerRect.top >= currentElementRect.top;
                        if (!currentElementIsAtTop && nextElementIndex >= 1) {
                            setCurrentFloatingElement(elements[nextElementIndex - 1]);
                            setNextElementIndex(nextElementIndex - 1);
                        }
                    }
                }
            }
        };

        const container = document.getElementById('container');
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [currentFloatingElement, nextElementIndex, elements]);

    return (
        <div id="container" className="h-full overflow-auto">
            <div className="floating-element">
                {currentFloatingElement ? currentFloatingElement.textContent : ""}
            </div>
            <div id="output">
                <EntryList entryList={journal} />
            </div>
        </div>
    );
};

export default ScrollDetectorObject;