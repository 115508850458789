import { useAuth0 } from '@auth0/auth0-react';
import createApiClient from './apiClient';
import { config } from '../components/Util'

let apiInstance = null;

export const useApiClient = () => {
    const { getAccessTokenSilently } = useAuth0();

    if (!apiInstance) {
        apiInstance = createApiClient(config.apiUrl, getAccessTokenSilently);
    }

    return apiInstance;
};
