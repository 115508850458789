import React, { useState } from 'react';
import Util from '../Util';
import styles from './ListManagement.module.css';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useAuth0 } from "@auth0/auth0-react";
import { getUserHabits, createHabit, updateHabitName, deleteHabit } from '../api';
import { useApiClient } from '../../service/useApiClient';

function HabitsPage() {
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingValue, setEditingValue] = useState('');
  const queryClient = useQueryClient();
  const { isAuthenticated } = useAuth0();
  const api = useApiClient();

  const { data: habitsData } = useQuery({
    queryKey: Util.config.habitsQueryKey,
    queryFn: async () => {
      setItems(await getUserHabits(api));
    },
    enabled: isAuthenticated, // Ensure API call only happens when authenticated
    staleTime: 5 * 60 * 1000, // Keep tags fresh for 5 minutes
  });

  const handleAdd = async () => {
    try {
      const response = await createHabit(api, newItem);
      setItems([...items, response.data]);
      setNewItem('');
    } catch (error) {
      // ;
      console.error('Error adding item:', error);
    }
  };

  const handleEdit = async (index) => {
    try {
      debugger;
      const item = items[index];
      const response = await updateHabitName(api, item.taskId, editingValue);
      const updatedItems = [...items];
      updatedItems[index] = response.data;
      setItems(updatedItems);
      setEditingIndex(null);
      setEditingValue('');
    } catch (error) {
      console.error('Error editing item:', error);
    }
  };

  const handleDelete = async (taskId) => {
    try {
      await deleteHabit(api, taskId);
      const updatedItems = items.filter(item => item.taskId !== taskId);
      setItems(updatedItems);
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Habits</h1>

      <ul className={styles.list}>
        {items.map((item, index) => (
          <li key={item.taskId} className={styles.listItem}>
            {editingIndex === index ? (
              <>
                <input
                  type="text"
                  value={editingValue}
                  onChange={(e) => setEditingValue(e.target.value)}
                  className={styles.input}
                />
                <div className={styles.actionButtons}>
                  <button onClick={() => handleEdit(index)} className={styles.button}>Save</button>
                  <button onClick={() => setEditingIndex(null)} className={styles.button}>Cancel</button>
                </div>
              </>
            ) : (
              <>
                {item.name}
                <div className={styles.actionButtons}>
                  <button onClick={() => { setEditingIndex(index); setEditingValue(item.name); }} className={styles.button}>
                    Edit
                  </button>
                  <button onClick={() => handleDelete(item.taskId)} className={styles.button}>Delete</button>
                </div>
              </>
            )}
          </li>
        ))}
      </ul>

      <input
        type="text"
        value={newItem}
        onChange={(e) => setNewItem(e.target.value)}
        placeholder="Add new item"
        className={styles.input}
      />
      <button onClick={handleAdd} className={styles.button}>Add</button>
    </div>
  );
}

export default HabitsPage;