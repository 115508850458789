import { useState, useEffect } from "react";
import './elements.css'; // Import CSS file for button styles
import React from "react";

interface ToggleButtonProps {
    tagId: string;
    name: string;
    color: string;
    isToggled: boolean;
    handleButtonToggle: (tagId: string) => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = (props) => {
    const [isClicked, setIsClicked] = useState(false);


    useEffect(() => {
        setIsClicked(props.isToggled);
    }, [props.isToggled]);

    const handleChildButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.handleButtonToggle(props.tagId);
        setIsClicked(!isClicked);
    };

    const className = `button ${isClicked ? 'on' : ''} bg-${props.color}-500 text-white font-bold text-sm py-2 px-4 items-center rounded-full flex-item`

    return (
        <div className="flex">
            <button
                className={className}
                onClick={handleChildButtonClick}
                data-quickadd-tag-button="true"
                type="button"
            >
                {props.name}
            </button>
        </div>
    );
}

export default ToggleButton;