import React from 'react';
import ReadTagsComponent from '../elements/ReadTagsComponent';
import { Outlet } from 'react-router-dom';

const AdminPage = (props) => {

  return (
    <div>
      <ReadTagsComponent />
      <Outlet />
    </div>
  );
};

export default AdminPage;
