import React from 'react';

const NotesComponent = (props) => {

    const handleInputChange = async (e) => {
        props.handleInputChange(e);
    };

    const handleKeyDown = (e) => {
        if (e.key === ' ') {
            // Prevent default behavior (form submission)
            const { selectionStart, value } = e.target;
            const lines = value.split('\n');
            const currentLine = lines[lines.length - 1];

            // Check if the current line starts with a period followed by a space
            if (currentLine.trim().startsWith('.')) {
                e.preventDefault();
                // Replace the current line with a bulleted item
                var bullet = ' • ';
                if (lines.length > 1) {
                    bullet = '\n' + bullet;
                }
                const newContent = value.substring(0, selectionStart - 2) + bullet + currentLine.substring(2);
                props.handleInputChange(newContent);
            }
        }
        if (e.key === 'Enter') {
            // Prevent default behavior (form submission)
            const { selectionStart, value } = e.target;
            const lines = value.split('\n');
            const currentLine = lines[lines.length - 1];

            // Check if the current line starts with a period followed by a space
            if (currentLine.trim().startsWith('.')) {
                e.preventDefault();
                // Replace the current line with a bulleted item
                var bullet = '\n • ';
                const newContent = value.substring(0, selectionStart - 2) + bullet + currentLine.substring(2);
                props.handleInputChange(newContent);
            }
        }
    };

    return (
        <textarea
            id="current-journal"
            className="textbox text-base w-full	p-5 h-48"
            value={props.content}
            onKeyDown={handleKeyDown}
            style={{ border: '1px solid #ccc', minHeight: '100px', resize: 'none' }}
            onInput={handleInputChange}></textarea>
    );
};

export default NotesComponent;
