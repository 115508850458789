// src/components/Checklist.js
import React, { useEffect, useState } from 'react';
import CSS from "csstype";

type Item = {
    taskId?: string;
    isCompleted?: boolean;
    name?: string;
    tags?: Tag[];
};

type Tag = {
    name?: string;
};

const ChecklistItem = (props) => {
    const [item, setItem] = useState<Item>({});

    const handleToggle = () => {
        props.handleToggle(item);
    };

    useEffect(() => {
        setItem(props.item);
    }, [props.item]);

    return (
        <div>
            <li key={item.taskId} style={checklistItem} onClick={() => handleToggle()}>
                <div style={checkboxWrapper}>
                    <input
                        type="checkbox"
                        checked={props.isComplete}
                        style={checkboxInput}
                    />
                    {props.isComplete && <span style={checkmark}>✓</span>}
                </div>


                <div className={props.isComplete ? 'complete' : 'incomplete'} style={props.isComplete ? checkedLabel : null}>
                    {item.name}
                </div>

                {item.tags && item.tags.map((tag, index) => (
                    <p className="ml-1 bg-blue-500 text-white font-bold text-xs py-1 px-2 items-center rounded-full flex-item">{tag.name}</p>
                ))
                }
            </li>
        </div>
    );
};

export default ChecklistItem;
const checklistItem: CSS.Properties = {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    cursor: 'pointer',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
}
const checkboxWrapper: CSS.Properties = {
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #ccc',
    borderRadius: '4px', // Adjust border radius for a slightly rounded rectangle
    marginRight: '10px',
    position: 'relative',
}
const checkboxInput: CSS.Properties = {
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
}
const checkmark: CSS.Properties = {
    position: 'absolute',
    top: '50%', // Position the checkmark at the vertical center of the checkbox
    left: '50%', // Position the checkmark at the horizontal center of the checkbox
    transform: 'translate(-50%, -50%)', // Center the checkmark using translate
    fontSize: '1.2em',
    color: '#007bff', // Color of the checkmark (blue)
}
const checkedLabel: CSS.Properties = {
    textDecoration: 'line-through',
    color: '#888',
    textAlign: 'left',
    flex: 1 // Align text to the left
}
